<template>
  <div>
    <d-body-top-bar :title="$t('views.client.details.body.left.subscription-affect.title')">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('views.client.your-customers') }} /
          <label
            class="inactive-tree link-area pointer"
            @click="$router.push({ name: 'customer_details', params: { id: $route.params.id} })"
          >
            {{ name ? name : $t('views.client.details.body.left.subscription-affect.profile') }} /
          </label>
        </label>
        <label class="bg-doinsport-primary ml-1">
          {{ $t('views.client.details.body.left.subscription-affect.title') }}
        </label>
      </div>
      <div slot="icons">
      </div>
    </d-body-top-bar>
    <div
      v-show="step === 0"
      class="container-fluid"
    >
      <b-card
        :class="$store.getters['layout/getInnerWidth'] > 900 ? '' : 'p-3'"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <subscription-selection
              class="mt-3"
              :selected-subscriptions="selectedSubscriptions"
              :selected-rates="selectedRates"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col align="right">
            <d-button
              text="general.actions.next"
              class="d-btn btn d-btn-primary font-text-title mr-2"
              :class="selectedSubscriptions.values.length === 0 ? 'disabled' : ''"
              @on:button-click="() => selectedSubscriptions.values.length > 0 ? step = 1 : null "
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div
      v-if="step === 1"
      v-show="step === 1"
      class="container-fluid"
    >
      <b-card>
        <b-row>
          <b-col cols="12">
            <subscription-resume
              :selected-rates="selectedRates"
              :selected-subscriptions="selectedSubscriptions"
              class="mt-3"
              @on:previous="step = 0"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import {getClubClient} from "@api/doinsport/services/client/client.api";
import Client from "@/classes/doinsport/Client";

export default {
  data: () => ({
    customer: null,
    step: 0,
    selectedRates: {values: [], selected: false},
    selectedSubscriptions: {values: [], selected: false},
  }),
  components: {
    SubscriptionResume: () => import('./SubscriptionResume'),
    SubscriptionSelection: () => import('./SubscriptionSelection'),
  },
  computed: {
    name() {
      if (this.customer) {
        return this.customer.fullName;
      }
      return null;
    },
  },
  methods: {
    loadCustomer() {
      getClubClient(this.$route.params.id)
        .then((response) => {
          this.customer = new Client(response.data, {serialize: true});
        })
      ;
    }
  },
  created() {
    this.loadCustomer();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
</style>
